<script lang="ts">
import OzBaseButton from '@@/library/v4/components/OzBaseButton.vue'
import OzDivider, { OzDividerColorScheme } from '@@/library/v4/components/OzDivider.vue'
import OzIcon from '@@/library/v4/components/OzIcon.vue'
import type { VueCssClass } from '@@/types'
import { uniqueId } from 'es-toolkit/compat'
import type { ComponentPublicInstance, StyleValue } from 'vue'
import { computed, onMounted, ref, useSlots, watch } from 'vue'

export enum OzMenuRowsSizePreset {
  H40px = 'H40px',
  H44px = 'H44px',
  H48px = 'H48px',
  H56px = 'H56px',
  H64px = 'H64px',
}

export enum OzMenuRowsFocusStyle {
  None = 'None',
  Bubble100 = 'Bubble100',
  Bubble200 = 'Bubble200',
  Grey100 = 'Grey100',
  HighlightText = 'HighlightText',
  ButtonDefault = 'ButtonDefault',
}

export enum OzMenuRowsRowStyle {
  Default = 'Default',
  Primary = 'Primary',
  Secondary = 'Secondary',
  Danger = 'Danger',
  Disabled = 'Disabled',
  Highlighted = 'Highlighted',
  Focused = 'Focused',
}

export enum OzMenuRowsHrefMode {
  Default = 'Default',
  DisplayOnly = 'DisplayOnly',
  HistoryPushState = 'HistoryPushState',
}

export enum OzMenuRowsRowRole {
  MenuItem = 'menuitem',
  MenuItemRadio = 'menuitemradio',
  MenuItemCheckbox = 'menuitemcheckbox',
}

export interface OzMenuRowsRow<EventPayload = any, EventMorePayload = any> {
  // event payload that is emitted when the row is selected
  eventPayload?: EventPayload
  eventMorePayload?: EventMorePayload

  // navigation state
  href?: string
  hrefMode?: OzMenuRowsHrefMode
  isDisabled?: boolean

  // this is used to identifying active row that is given from the props
  ref?: string

  // misc
  testId?: string
  analyticsId?: string

  // text customization
  text?: string
  htmlText?: string
  hoverText?: string

  // text styling
  textStyleClass?: string
  textTruncated?: boolean

  // left icon customization
  icon?: string
  iconSize?: number
  leftIconClasses?: VueCssClass

  // right icon customization
  rightIcon?: string | null
  rightIconTestId?: string
  rightIconSize?: number
  rightLabel?: string
  rightLabelClasses?: VueCssClass
  rightIconAriaLabel?: string
  rightIconHighlighted?: boolean
  rightIconHasPopup?: string

  // row styling
  rowStyle?: OzMenuRowsRowStyle
  rowClasses?: VueCssClass

  // Accessibility
  ariaChecked?: string
  ariaLabel?: string
  ariaHasPopup?: string
  ariaDescribedby?: string
  /**
   * The description will be inserted in an SR-only container
   * and will be connected to the row through aria-describedby.
   */
  ariaDescription?: string
  role?: OzMenuRowsRowRole
}

export default {}
</script>
<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    /**
     * Describes the rows
     */
    rows: OzMenuRowsRow[]
    focusStyle: OzMenuRowsFocusStyle
    darkMode?: boolean | 'auto'
    /**
     * Size of the rows.
     * All sizes have at least a 44px by 44px touch area and are thus mobile compatible.
     */
    sizePreset?: OzMenuRowsSizePreset

    /**
     * Set to true if you want to concatenate multiple `<OzMenuRows>`s.
     */
    xLastRowDivider?: boolean

    /**
     * Use this to set the same icon for all the rows.
     */
    commonSettings?: OzMenuRowsRow

    /**
     * Use this to decide how the `activeRow` should be scrolled into view.
     */
    isPhone?: boolean
    /**
     * Be scrolled into view to be visible to the user.
     *
     * It has to be matched with `OzMenuRowsRow.ref`.
     */
    activeRow?: string

    /**
     * List wrapper role for accessibility
     */
    accessibilityRole?: 'menu' | 'group'
    dividerStyle?: StyleValue
  }>(),
  {
    darkMode: 'auto',
    sizePreset: OzMenuRowsSizePreset.H48px,
    xLastRowDivider: false,
    commonSettings: () => [] as OzMenuRowsRow,
    isPhone: false,
    activeRow: '',
    accessibilityRole: undefined,
    dividerStyle: undefined,
  },
)

const emit = defineEmits<{
  (e: 'select-row', event: Event, payload: any): void
  (e: 'mouseenter-row', event: Event, payload: any): void
  (e: 'mouseleave-row', event: Event, payload: any): void
  (e: 'focus-row', event: Event, payload: any): void
  (e: 'blur-row', event: Event, payload: any): void
  (e: 'select-more-icon', event: Event, payload: any): void
}>()

const slots = useSlots()
const isRightIconInteractive = (row: OzMenuRowsRow): boolean => {
  return row.rightIcon && row.eventMorePayload
}

type RowDetails = { el: HTMLElement | null; rowRef: string | undefined }
const rowEls = ref<Array<RowDetails>>([])
const setRowElRef = (index: number, el: Element | ComponentPublicInstance | null) => {
  if (rowEls.value[index] && el) rowEls.value[index].el = el as HTMLElement
}

watch(
  () => props.rows,
  (newRows) => {
    rowEls.value = newRows.map((row) => ({ el: null, rowRef: row.ref }))
  },
  { immediate: true, deep: true },
)

const iconSize = computed(() => {
  return {
    [OzMenuRowsSizePreset.H40px]: 20,
    [OzMenuRowsSizePreset.H44px]: 16,
    [OzMenuRowsSizePreset.H48px]: 24,
    [OzMenuRowsSizePreset.H56px]: 24,
    [OzMenuRowsSizePreset.H64px]: 32,
  }[props.sizePreset]
})

const rowsData = computed(() =>
  props.rows.map((row) => ({
    rowStyle: OzMenuRowsRowStyle.Default,
    ...props.commonSettings,
    ...row,
    ariaDescribedby: row.ariaDescription ? uniqueId('oz-menu-row-description-') : undefined,
  })),
)

const xLeftIcon = computed(() => rowsData.value.some((row) => row.icon))

const textHighlightStyles = computed(() => [
  {
    'hhover:group-hover:text-grape-500 hhover:dark:group-hover:text-canary-500': props.darkMode === 'auto',
    'hhover:group-hover:text-grape-500': props.darkMode === false,
    'hhover:group-hover:text-canary-500': props.darkMode === true,
  },
  {
    'group-focus-visible:text-grape-500 dark:group-focus-visible:text-canary-500': props.darkMode === 'auto',
    'group-focus-visible:text-grape-500': props.darkMode === false,
    'group-focus-visible:text-canary-500': props.darkMode === true,
  },
  {
    'group-active:text-grape-500 dark:group-active:text-canary-500': props.darkMode === 'auto',
    'group-active:text-grape-500': props.darkMode === false,
    'group-active:text-canary-500': props.darkMode === true,
  },
])

const isRowSelected = (row: OzMenuRowsRow): boolean => {
  return row.ref === props.activeRow || row.ariaChecked === 'true'
}

const getAccessibilityAttrs = (row: OzMenuRowsRow): Record<string, string | undefined> => {
  return {
    'aria-checked': row.ariaChecked,
    'aria-label': row.ariaLabel,
    'aria-describedby': row.ariaDescribedby,
    'aria-haspopup': row.ariaHasPopup,
    role: row.role || (props.accessibilityRole ? 'menuitem' : undefined),
  }
}

const selectRow = (event: Event, row: OzMenuRowsRow): void => {
  if (row.hrefMode !== OzMenuRowsHrefMode.Default) event.preventDefault()
  if (row.hrefMode === OzMenuRowsHrefMode.HistoryPushState) {
    // Store the url in the history state so you can read it later
    window.history.pushState({ url: row.href }, '', row.href)
  }

  emit('select-row', event, row.eventPayload)
}

const selectRightIcon = (event: Event, row: OzMenuRowsRow): void => {
  emit('select-more-icon', event, row.eventMorePayload)
}

const shouldShowRowFocusRing = (row: OzMenuRowsRow): boolean => {
  return !row.rightLabel
}

const scrollActiveRowIntoView = () => {
  if (!props.activeRow) return
  const activeRow = Object.values<RowDetails>(rowEls.value).find((obj) => obj.rowRef === props.activeRow)
  if (!activeRow) return
  activeRow?.el?.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'nearest' })
}

const focusRow = (textContent: string): void => {
  const elIndex = rowEls.value.findIndex((rowEl) => rowEl.el?.textContent?.includes(textContent))
  if (elIndex !== -1) {
    const focusableEl = rowEls.value[elIndex].el?.firstChild as HTMLElement | null
    focusableEl?.focus()
  }
}

const focusNthRow = (index: number): void => {
  const nthRow = rowEls.value[index].el?.firstChild as HTMLElement | null
  nthRow?.focus()
}

const SCROLL_DELAY = 500
onMounted(() => {
  if (props.isPhone) {
    // Prevent the UI from laggy on mobile when scrolling into view that contains the `activeRow`
    setTimeout(() => {
      scrollActiveRowIntoView()
    }, SCROLL_DELAY)
  } else {
    scrollActiveRowIntoView()
  }
})

defineExpose({
  scrollActiveRowIntoView,
  focusRow,
  focusNthRow,
})
</script>

<template>
  <div :class="['font-sans']" :role="accessibilityRole" :data-dark-mode="darkMode">
    <template v-for="(row, index) in rowsData">
      <div
        :key="`${row.icon}-${index}`"
        :ref="(el) => setRowElRef(index, el)"
        :data-testid="row.testId"
        :data-pw="row.testId"
        :data-pepin-id="row.analyticsId"
        data-pepin-trigger="click"
        :class="[
          // Always set `display` to `block` to avoid overrides
          'block',
          'relative',
          // This is the space between the hover box and the edge of the row
          'py-1',
          'px-2',
        ]"
        role="presentation"
      >
        <component
          :is="row.href ? 'a' : 'div'"
          :tabindex="0"
          :data-testid="`${row.testId}Button`"
          :aria-disabled="row.isDisabled"
          :href="row.href || null"
          :rel="row.href && 'noopener nofollow'"
          v-bind="getAccessibilityAttrs(row)"
          :class="[
            'group',
            // Wanted to use the resets that's already on `<OzBaseButton>`, but can't use a `<button>` element here
            // because we use a `<button>` for the `rightIcon` and nested `<button>`s are invalid.
            'focus-visible:outline-none',

            row.isDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
            // TEXT COLORS
            row.rowStyle === OzMenuRowsRowStyle.Default && {
              'text-dark-text-100': darkMode === false,
              'text-light-text-100': darkMode === true,
              'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
            },
            row.rowStyle === OzMenuRowsRowStyle.Disabled &&
              (sizePreset === OzMenuRowsSizePreset.H44px || sizePreset === OzMenuRowsSizePreset.H40px) && {
                'text-dark-text-300': darkMode === false,
                'text-light-text-300': darkMode === true,
                'text-dark-text-300 dark:text-light-text-300': darkMode === 'auto',
              },
            row.rowStyle === OzMenuRowsRowStyle.Disabled &&
              sizePreset === OzMenuRowsSizePreset.H48px && {
                'text-dark-text-400': darkMode === false,
                'text-light-text-400': darkMode === true,
                'text-dark-text-400 dark:text-light-text-400': darkMode === 'auto',
              },
            row.rowStyle === OzMenuRowsRowStyle.Highlighted && {
              'font-semibold': true,
              'text-grape-500': darkMode === false,
              'text-canary-500': darkMode === true,
              'text-grape-500 dark:text-canary-500': darkMode === 'auto',
            },
            row.rowStyle === OzMenuRowsRowStyle.Primary && 'text-padlet-pink-500',
            row.rowStyle === OzMenuRowsRowStyle.Secondary && {
              'text-dark-text-200': darkMode === false,
              'text-light-text-200': darkMode === true,
              'text-dark-text-200 dark:text-light-text-200': darkMode === 'auto',
            },
            row.rowStyle === OzMenuRowsRowStyle.Danger && 'text-danger-100',
            row.rowStyle === OzMenuRowsRowStyle.Focused && [
              'ring-2',
              {
                'ring-grape-500': darkMode === false,
                'ring-canary-500': darkMode === true,
                'ring-grape-500 dark:ring-canary-500': darkMode === 'auto',
              },
            ],
            // SHAPE
            'rounded-xl',
            // Combined with the space outside the hover box, the row has 16px of x-padding. Only H40px has shorter y-padding & start-padding.
            sizePreset === OzMenuRowsSizePreset.H40px ? 'py-1.5 ps-1.5 pe-2' : 'p-2',

            // 44px - 2*4px (padding outside hover box) - 2*8px (padding outside hover box) = 20px
            (sizePreset === OzMenuRowsSizePreset.H40px || sizePreset === OzMenuRowsSizePreset.H44px) && 'min-h-[20px]',
            // 48px - 2*4px (padding outside hover box) - 2*8px (padding outside hover box) = 24px
            sizePreset === OzMenuRowsSizePreset.H48px && 'min-h-[24px]',
            // 56px - 2*4px (padding outside hover box) - 2*8px (padding outside hover box) = 32px
            sizePreset === OzMenuRowsSizePreset.H56px && 'min-h-[32px]',
            // 64px - 2*4px (padding outside hover box) - 2*8px (padding outside hover box) = 40px
            sizePreset === OzMenuRowsSizePreset.H64px && 'min-h-[40px]',

            // HIGHLIGHT STYLES
            !row.isDisabled && [
              'transition-colors',
              focusStyle === OzMenuRowsFocusStyle.Bubble100 && [
                {
                  'hhover:hover:bg-light-ui-100': darkMode === false,
                  'hhover:hover:bg-dark-ui-100': darkMode === true,
                  'hhover:hover:bg-light-ui-100 hhover:dark:hover:bg-dark-ui-100': darkMode === 'auto',
                },
                {
                  'active:bg-light-ui-100': darkMode === false,
                  'active:bg-dark-ui-100': darkMode === true,
                  'active:bg-light-ui-100 dark:active:bg-dark-ui-100': darkMode === 'auto',
                },
              ],
              focusStyle === OzMenuRowsFocusStyle.Bubble200 && [
                {
                  'hhover:hover:bg-light-ui-200': darkMode === false,
                  'hhover:hover:bg-dark-ui-200': darkMode === true,
                  'hhover:hover:bg-light-ui-200 hhover:dark:hover:bg-dark-ui-200': darkMode === 'auto',
                },
                {
                  'active:bg-light-ui-200': darkMode === false,
                  'active:bg-dark-ui-200': darkMode === true,
                  'active:bg-light-ui-200 dark:active:bg-dark-ui-200': darkMode === 'auto',
                },
              ],
              focusStyle === OzMenuRowsFocusStyle.Grey100 && [
                {
                  'hhover:hover:bg-grey-100': darkMode === false,
                  'hhover:dark:hover:bg-grey-700': darkMode === true,
                  'hhover:hover:bg-grey-100 hhover:dark:hover:bg-grey-700': darkMode === 'auto',
                },
                {
                  'active:bg-grey-100': darkMode === false,
                  'active:bg-grey-700': darkMode === true,
                  'active:bg-grey-100 dark:active:bg-grey-700': darkMode === 'auto',
                },
              ],
              focusStyle === OzMenuRowsFocusStyle.ButtonDefault && [
                {
                  'hhover:hover:bg-button-light': darkMode === false,
                  'hhover:hover:bg-button-dark': darkMode === true,
                  'hhover:hover:bg-button-light hhover:dark:hover:bg-button-dark': darkMode === 'auto',
                },
                {
                  'active:bg-button-light': darkMode === false,
                  'active:bg-button-dark': darkMode === true,
                  'active:bg-button-light dark:active:bg-button-dark': darkMode === 'auto',
                },
              ],
              focusStyle === OzMenuRowsFocusStyle.HighlightText &&
                row.rowStyle === OzMenuRowsRowStyle.Default &&
                textHighlightStyles,
            ],

            // FOCUS STYLES
            shouldShowRowFocusRing(row) && [
              'focus-visible:ring-2',
              {
                'focus-visible:ring-grape-500': darkMode === false,
                'focus-visible:ring-canary-500': darkMode === true,
                'focus-visible:ring-grape-500 dark:focus-visible:ring-canary-500': darkMode === 'auto',
              },
            ],

            // INTERNAL LAYOUT
            'flex',
            'items-center',
            row.rowClasses,
          ]"
          @click.prevent="!row.isDisabled && selectRow($event, row)"
          @keydown.enter.prevent="!row.isDisabled && selectRow($event, row)"
          @keydown.space.prevent="!row.isDisabled && selectRow($event, row)"
          @mouseenter="!row.isDisabled && $emit('mouseenter-row', $event, row)"
          @mouseleave="!row.isDisabled && $emit('mouseleave-row', $event, row)"
          @focus="!row.isDisabled && $emit('focus-row', $event, row)"
          @blur="!row.isDisabled && $emit('blur-row', $event, row)"
        >
          <!-- LEFT ICON -->
          <OzIcon
            v-if="xLeftIcon && row.icon"
            :class="[
              {
                'me-3.5': sizePreset === OzMenuRowsSizePreset.H40px,
                'me-3': sizePreset === OzMenuRowsSizePreset.H44px,
                'me-4': sizePreset === OzMenuRowsSizePreset.H48px || sizePreset === OzMenuRowsSizePreset.H56px,
              },
              !row.isDisabled &&
                [
                  OzMenuRowsFocusStyle.Bubble100,
                  OzMenuRowsFocusStyle.Bubble200,
                  OzMenuRowsFocusStyle.ButtonDefault,
                ].includes(focusStyle) &&
                row.rowStyle === OzMenuRowsRowStyle.Default &&
                textHighlightStyles,
              // Icon color - Secondary colors to text
              !row.leftIconClasses &&
                row.rowStyle === OzMenuRowsRowStyle.Default && {
                  'text-dark-text-200': darkMode === false,
                  'text-light-text-200': darkMode === true,
                  'text-dark-text-200 dark:text-light-text-200': darkMode === 'auto',
                },
              row.leftIconClasses,
            ]"
            :name="row.icon"
            :size="row.iconSize || iconSize"
          />
          <slot v-else-if="slots.startAdornment" name="startAdornment" :row="row" :icon-size="iconSize"></slot>

          <!-- TEXT -->
          <div
            :class="[
              row.textTruncated && 'truncate',
              'flex-1',
              'min-w-0',
              row.textStyleClass || {
                'text-body-small':
                  sizePreset === OzMenuRowsSizePreset.H40px || sizePreset === OzMenuRowsSizePreset.H44px,
                'text-body': sizePreset === OzMenuRowsSizePreset.H48px || sizePreset === OzMenuRowsSizePreset.H64px,
              },
              // Active styles
              !row.isDisabled && {
                'group-active:text-grape-500': darkMode === false,
                'group-active:text-canary-500': darkMode === true,
                'group-active:text-grape-500 dark:group-active:text-canary-500': darkMode === 'auto',
              },
              !xLeftIcon && 'ps-0.5',
              isRightIconInteractive(row) && 'pe-6',
            ]"
            :title="row.hoverText || row.text"
          >
            <!-- eslint-disable vue/no-v-html -->
            <span
              v-if="row.htmlText"
              :class="
                isRowSelected(row) &&
                '[&>:first-child]:high-contrast:underline [&>:first-child]:high-contrast:decoration-12'
              "
              v-html="row.htmlText"
            />

            <span v-else :class="isRowSelected(row) && 'high-contrast:underline high-contrast:decoration-12'">{{
              row.text
            }}</span>

            <!-- Slot for custom text content -->
            <slot name="rowTextContent" :row="row" />
          </div>

          <!-- ARIA description -->
          <span v-if="row.ariaDescription" :id="row.ariaDescribedby" class="sr-only">{{ row.ariaDescription }}</span>

          <!-- Right side icon + label -->
          <div
            v-if="row.rightIcon && row.rightLabel"
            :class="[
              'flex items-center',
              'rounded-lg',
              'self-stretch',
              'px-1 -me-1', // need padding on both sides for displaying border
              // FOCUS STYLES
              'group-focus-visible:ring-2',
              {
                'group-focus-visible:ring-grape-500': darkMode === false,
                'group-focus-visible:ring-canary-500': darkMode === true,
                'group-focus-visible:ring-grape-500 dark:group-focus-visible:ring-canary-500': darkMode === 'auto',
              },
            ]"
          >
            <span :class="['text-body-small', row.rightLabelClasses]">{{ row.rightLabel }}</span>
            <OzIcon
              v-if="row.rightIcon"
              :name="row.rightIcon"
              :size="row.rightIconSize || iconSize"
              :aria-label="row.rightIconAriaLabel"
              :class="[
                'ms-2',
                !row.isDisabled &&
                  row.rightIconHighlighted && {
                    'text-grape-500': darkMode === false,
                    'text-canary-500': darkMode === true,
                    'text-grape-500 dark:text-canary-500': darkMode === 'auto',
                  },
                row.isDisabled &&
                  row.rightIconHighlighted && {
                    'text-modal-overlay-grape': darkMode === false,
                    'text-modal-overlay-canary': darkMode === true,
                    'text-modal-overlay-grape dark:text-modal-overlay-canary': darkMode === 'auto',
                  },
                !row.isDisabled &&
                  !row.rightIconHighlighted &&
                  row.rowStyle === OzMenuRowsRowStyle.Default && {
                    'text-dark-text-200 hhover:group-hover:text-dark-text-100': darkMode === false,
                    'text-light-text-200 hhover:group-hover:text-light-text-100': darkMode === true,
                    'text-dark-text-200 dark:text-light-text-200 hhover:group-hover:text-dark-text-100 hhover:dark:group-hover:text-light-text-100':
                      darkMode === 'auto',
                  },
                !row.isDisabled &&
                  focusStyle === OzMenuRowsFocusStyle.HighlightText &&
                  row.rowStyle === OzMenuRowsRowStyle.Default &&
                  textHighlightStyles,
              ]"
            />
          </div>
          <!-- Right side icon for display only -->
          <OzIcon
            v-else-if="row.rightIcon && !isRightIconInteractive(row)"
            :data-testid="row.rightIconTestId ?? `${row.testId}RightIcon`"
            :name="row.rightIcon"
            :size="row.rightIconSize || iconSize"
            :aria-label="row.rightIconAriaLabel"
            :class="[
              'ms-2',
              !row.isDisabled &&
                row.rightIconHighlighted && {
                  'text-grape-500': darkMode === false,
                  'text-canary-500': darkMode === true,
                  'text-grape-500 dark:text-canary-500': darkMode === 'auto',
                },
              row.isDisabled &&
                row.rightIconHighlighted && {
                  'text-modal-overlay-grape': darkMode === false,
                  'text-modal-overlay-canary': darkMode === true,
                  'text-modal-overlay-grape dark:text-modal-overlay-canary': darkMode === 'auto',
                },
              !row.isDisabled &&
                !row.rightIconHighlighted &&
                row.rowStyle === OzMenuRowsRowStyle.Default && {
                  'text-dark-text-200 hhover:group-hover:text-dark-text-100': darkMode === false,
                  'text-light-text-200 hhover:group-hover:text-light-text-100': darkMode === true,
                  'text-dark-text-200 dark:text-light-text-200 hhover:group-hover:text-dark-text-100 hhover:dark:group-hover:text-light-text-100':
                    darkMode === 'auto',
                },
              !row.isDisabled &&
                focusStyle === OzMenuRowsFocusStyle.HighlightText &&
                row.rowStyle === OzMenuRowsRowStyle.Default &&
                textHighlightStyles,
            ]"
          />
          <!-- Right side label only -->
          <span
            v-else-if="row.rightLabel"
            :class="[
              'text-body-small',
              'me-1',
              {
                'text-dark-text-200': darkMode === false,
                'text-light-text-200': darkMode === true,
                'text-dark-text-200 dark:text-light-text-200': darkMode === 'auto',
              },
              row.rightLabelClasses,
            ]"
            >{{ row.rightLabel }}</span
          >
        </component>
        <!-- If event more payload is expected to be emitted by right icon, we will render an absolutely positioned button that wraps the icon -->
        <!-- This helps to avoid the nested interactive controls a11y issue -->
        <OzBaseButton
          v-if="row.rightIcon && isRightIconInteractive(row)"
          :data-testid="row.rightIconTestId"
          :class="[
            'absolute',
            'bg-transparent',
            'inset-y-3 end-4',
            'focus-visible:ring-2 rounded-lg',
            {
              'focus-visible:ring-grape-500': darkMode === false,
              'focus-visible:ring-canary-500': darkMode === true,
              'focus-visible:ring-grape-500 dark:focus-visible:ring-canary-500': darkMode === 'auto',
            },
          ]"
          :aria-label="row.rightIconAriaLabel"
          :aria-haspopup="row.rightIconHasPopup"
          :role="accessibilityRole === 'menu' ? 'menuitem' : undefined"
          @click.prevent="!row.isDisabled && selectRightIcon($event, row)"
        >
          <OzIcon
            :data-testid="`${row.rightIconTestId}RightIcon`"
            :name="row.rightIcon"
            :size="row.rightIconSize || iconSize"
            :aria-label="row.rightIconAriaLabel"
            :class="[
              !row.isDisabled &&
                row.rightIconHighlighted && {
                  'text-grape-500': darkMode === false,
                  'text-canary-500': darkMode === true,
                  'text-grape-500 dark:text-canary-500': darkMode === 'auto',
                },
              row.isDisabled &&
                row.rightIconHighlighted && {
                  'text-modal-overlay-grape': darkMode === false,
                  'text-modal-overlay-canary': darkMode === true,
                  'text-modal-overlay-grape dark:text-modal-overlay-canary': darkMode === 'auto',
                },
              !row.isDisabled &&
                !row.rightIconHighlighted &&
                row.rowStyle === OzMenuRowsRowStyle.Default && {
                  'text-dark-text-200 hhover:group-hover:text-dark-text-100': darkMode === false,
                  'text-light-text-200 hhover:group-hover:text-light-text-100': darkMode === true,
                  'text-dark-text-200 dark:text-light-text-200 hhover:group-hover:text-dark-text-100 hhover:dark:group-hover:text-light-text-100':
                    darkMode === 'auto',
                },
              !row.isDisabled &&
                focusStyle === OzMenuRowsFocusStyle.HighlightText &&
                row.rowStyle === OzMenuRowsRowStyle.Default &&
                textHighlightStyles,
            ]"
          />
        </OzBaseButton>
      </div>

      <OzDivider
        v-if="xLastRowDivider || index < rows.length - 1"
        :key="`${row.icon}-${index}-divider`"
        :color-scheme="OzDividerColorScheme.Solid"
        :dark-mode="darkMode"
        :style="dividerStyle"
        :class="[
          !xLeftIcon && !slots.startAdornment && 'mx-4',
          // 14px (left of icon) + 20px (icon) + 12px (right of icon) = ms-11.5
          (xLeftIcon || slots.startAdornment) && sizePreset === OzMenuRowsSizePreset.H40px && 'ms-11.5 me-4',
          // 16px (left of icon) + 16px (icon) + 10px (right of icon) = ms-10.5
          (xLeftIcon || slots.startAdornment) && sizePreset === OzMenuRowsSizePreset.H44px && 'ms-10.5 me-4',
          // 16px (left of icon) + 24px (icon) + 14px (right of icon) = ms-13.5
          (xLeftIcon || slots.startAdornment) && sizePreset === OzMenuRowsSizePreset.H48px && 'ms-13.5 me-4',
          // 16px (left of icon) + 24px (icon) + 14px (right of icon) = ms-13.5
          (xLeftIcon || slots.startAdornment) && sizePreset === OzMenuRowsSizePreset.H56px && 'ms-13.5 me-2',
          // 16px (left of icon) + 32px (icon) + 14px (right of icon) = ms-15.5
          (xLeftIcon || slots.startAdornment) && sizePreset === OzMenuRowsSizePreset.H64px && 'ms-15.5 me-2',
        ]"
      />
    </template>
  </div>
</template>
